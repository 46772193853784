import React from 'react'
import { toast } from 'react-toastify';
import { auth } from '../../firebase';


function SignOut(props) {
    const handleSignOut = () => {
        auth.signOut();
        toast.success("You have successfully logged out.")
    }

    return (
        <div onClick={() => handleSignOut()}>{props.children}</div>
    )
}
export default SignOut;
