import { applyActionCode, confirmPasswordReset, signInWithEmailAndPassword, verifyPasswordResetCode } from 'firebase/auth';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import MiniLoader from '../../../utils/MiniLoader';
import { toast } from 'react-toastify';
import { auth as auth2 } from '../../../firebase';

export default function VerifyEmail({auth, actionCode, continueUrl=null, lang=null}) {

  

    const [success, setSuccess] = useState({success:false,message:''});
      const [loader, setLoader] = useState(false);
      const [userData, setUserData] = useState({
    
          password: '',
          rePassword: '',
      });
  
      const navigate = useNavigate();
 
  useEffect(() => {

    if(auth2.currentUser)  auth2.signOut()
    handleVerifyEmail(auth,actionCode)
  
    return () => {
      
    }
  }, [])
  
  
  
 



    
    function handleVerifyEmail(auth, actionCode, continueUrl, lang) {
        
        setLoader(true);
        // Localize the UI to the selected language as determined by the lang
        // parameter.
        // Try to apply the email verification code.
        applyActionCode(auth, actionCode).then((resp) => {
          // Email address has been verified.
      
          // TODO: Display a confirmation message to the user.
          // You could also provide the user with a link back to the app.
      
          // TODO: If a continue URL is available, display a button which on
          // click redirects the user back to the app via continueUrl with
          // additional state determined from that URL's parameters.
          setSuccess({success:true,message:'Email has been verified✅'})

          setLoader(false);
          toast.success('Email address has been verified.')
       
        //  navigate('/')
        }).catch((error) => {
          // Code is invalid or expired. Ask the user to verify their email address
          // again.
          
          setLoader(false);
          setSuccess({success:false,message:"Invalid or expired action code. Try again later"})
          toast.error(`Invalid or expired action code. Try again later`)
        });
      }
    
  
      return (
          
          <main>
              <section className="signup__area po-rel-z1 pt-100 pb-145">
                  <div className="sign__shape">
                      <img className="man-1" src="assets/img/icon/sign/man-3.png" alt="img not found" />
                      <img className="man-2 man-22" src="assets/img/icon/sign/man-2.png" alt="img not found" />
                      <img className="circle" src="assets/img/icon/sign/circle.png" alt="img not found" />
                      <img className="zigzag" src="assets/img/icon/sign/zigzag.png" alt="img not found" />
                      <img className="dot" src="assets/img/icon/sign/dot.png" alt="img not found" />
                      <img className="bg" src="assets/img/icon/sign/sign-up.png" alt="img not found" />
                      <img className="flower" src="assets/img/icon/sign/flower.png" alt="img not found" />
                  </div>
                  <div className="container">
                      <div className="row">
                          <div className="col-xxl-8 offset-xxl-2 col-xl-8 offset-xl-2">
                              <div className="section__title-wrapper text-center mb-55">
                                  <h2 className="section__title mt-25">Verify your Email</h2>
  
                              </div>
                          </div>
                      </div>
                      <div className="row">
                          <div className="col-xxl-6 offset-xxl-3 col-xl-6 offset-xl-3 col-lg-8 offset-lg-2">
                              <div className="sign__wrapper white-bg">
                           
                              
                              <div className="sign__form">
                              {!loader ?  
                                 <>
                                  <p className={success.success?"":'text-danger'}>{success.message}</p>
                                          <Link className="e-btn w-100" to='/'> Back to Home</Link>
                                 </>


                                          :

                                          <>
                                          <p >Please Wait</p>
                                          <MiniLoader />
                                         </>
                                          
                                         }
                                  </div>
                                  
                                 
                                 
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
          </main>
      );
  }
  
  