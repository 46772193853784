import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';


import { toast } from 'react-toastify';
import MiniLoader from '../../utils/MiniLoader';

import { signInWithEmailAndPassword, updatePassword, updateEmail } from 'firebase/auth';
import { auth } from '../../firebase'
import { useUserContext } from '../../store/UserContext';

export default function SignInMain() {
    const [error, setError] = useState('');
    const [loader, setLoader] = useState(false);
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    })

    const { setUserAuth } = useUserContext()
    const navigate = useNavigate();


    const onChange = (e) => {
        setError('');
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoader(true);
        try {
            await signInWithEmailAndPassword(
                auth,
                formData.email,
                formData.password
            ).then((result) => {
                setLoader(false);
                setUserAuth(result.user)
                toast.success(`Welcome to Edu4all!`);
                handleBack()
            });
        } catch (e) {
            console.log(e)
            if (e.code === "auth/user-not-found") {
                setError("This email does not exist. Please check your email.");
            } else if (e.code === "auth/wrong-password") {
                setError("Incorrect password. Please try again.");
            } else if (e.code === "auth/too-many-requests") {
                setError("Account temporarily disabled. Please wait and try again later.");
            }
            else {
                setError("An error occurred. Please try again later.")
            }
            setLoader(false);
        }
    }


    const handleBack = () => {
        if (window.history.state && window.history.state.idx > 0) {
            navigate(-1);
        } else {
            navigate('/', { replace: true }); // the current entry in the history stack will be replaced with the new one with { replace: true }
        }
    };

    return (
        <main>
            <section className="signup__area po-rel-z1 pt-100 pb-145">
                <div className="sign__shape">
                    <img className="man-1" src="assets/img/icon/sign/man-1.png" alt="img not found" />
                    <img className="man-2" src="assets/img/icon/sign/man-2.png" alt="img not found" />
                    <img className="circle" src="assets/img/icon/sign/circle.png" alt="img not found" />
                    <img className="zigzag" src="assets/img/icon/sign/zigzag.png" alt="img not found" />
                    <img className="dot" src="assets/img/icon/sign/dot.png" alt="img not found" />
                    <img className="bg" src="assets/img/icon/sign/sign-up.png" alt="img not found" />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-8 offset-xxl-2 col-xl-8 offset-xl-2">
                            <div className="section__title-wrapper text-center mb-55">
                                <h2 className="section__title mt-25">Sign in to <br /> start.</h2>
                                <p>If you don't have an account you can <Link to="/sign-up">Register here!</Link></p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-6 offset-xxl-3 col-xl-6 offset-xl-3 col-lg-8 offset-lg-2">
                            <div className="sign__wrapper white-bg">

                                <div className="sign__form">
                                    <form onSubmit={handleLogin} >
                                        <div className="sign__input-wrapper mb-25">
                                            <h5>Email </h5>
                                            <div className="sign__input">
                                                <input
                                                    type="email"
                                                    placeholder="Email"
                                                    name='email'
                                                    value={formData.email}
                                                    onChange={onChange}
                                                    required />
                                                <i className="fas fa-envelope"></i>
                                            </div>
                                        </div>
                                        <div className="sign__input-wrapper mb-10">
                                            <h5>Password</h5>
                                            <div className="sign__input">
                                                <input
                                                    type="password"
                                                    placeholder="Password"
                                                    name='password'
                                                    value={formData.password}
                                                    onChange={onChange}
                                                    required />
                                                <i className="fas fa-lock"></i>
                                            </div>
                                        </div>
                                        <div className="sign__action d-sm-flex justify-content-between mb-30">

                                            {/* <div className="sign__agree d-flex align-items-center">
                                                <input className="m-check-input" type="checkbox" id="m-agree" />
                                                <label className="m-check-label" htmlFor="m-agree">Keep me signed in</label>
                                            </div> */}

                                            <div className="sign__forgot">
                                                <Link to="/forgot-password">Forgot your password?</Link>
                                            </div>
                                        </div>


                                        <p className="text-danger">{error}</p>
                                        <button type='submit' className="e-btn  w-100"> <span></span>{!loader ? ' Sign In' : <MiniLoader />}</button>
                                        <div className="sign__new text-center mt-20">
                                            <p>You don't have an account? <Link to="/sign-up">Sign Up</Link></p>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </main>
    );
}


