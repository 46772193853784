import React from 'react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function TestimonialThree() {
    const { t } = useTranslation();

    return (
        <section className="testimonial__area pt-100 pb-100" style={{ backgroundImage: `url(${'assets/img/testimonial/home-3/testimonial-bg-3.jpg'})` }}>
            <div className="container">
                <div className="row">
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-10">
                        <div className="testimonial__slider-3">
                            <h3 className="testimonial__title"> <br /> {t('testimonial.Community Feedback')}</h3>
                            <div className="testimonial__slider-wrapper swiper-container testimonial-text mb-35">
                                <div className="swiper-wrapper">
                                    <Swiper
                                        // install Swiper modules
                                        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                                        spaceBetween={30}
                                        slidesPerView={1}
                                        autoplaydisableoninteraction={"false"}
                                        autoplay={true}
                                        loop={true}
                                        breakpoints={{

                                            // when window width is >= 768px
                                            768: {
                                                slidesPerView: 1
                                            },
                                            1200: {
                                                // when window width is >= 992px
                                                slidesPerView: 1,
                                            }
                                        }}
                                    >
                                        <SwiperSlide>
                                            <div className="swiper-slide">
                                                <div className="testimonial__item-3">
                                                    <p>“Edu4all m'a beaucoup soutenu pour maîtriser  les compétences requises. En effet, les tests chronométrés et les exercices corrigés avec toutes les justifications m'ont permis d'évoluer et d'avoir plus de confiance en soi même.”</p>

                                                    <div className="testimonial__info-2 mb-45">
                                                        <h4>Joseph Nader,  </h4>
                                                        <span> St coeurs</span>
                                                    </div>

                                                    {/* <div className="testimonial__nav-thumb">
                                                        <img src="assets/img/testimonial/home-3/testi-1.jpg" alt="img not found" />
                                                    </div> */}
                                                </div>
                                            </div>
                                        </SwiperSlide>

                                        <SwiperSlide>
                                            <div className="swiper-slide">
                                                <div className="testimonial__item-3">
                                                    <p>“Avec Edu4all, je suis devenu passionné par les mathématiques.
Ce solutionnaire m'a aidé à améliorer mes compétences et à résoudre plus facilement les exercices avec une meilleure gestion du temps.
Elie Abi Ghosn st coeurs”</p>

                                                    <div className="testimonial__info-2 mb-45">
                                                        <h4>Elie Abi Ghosn, </h4>
                                                        <span> St coeurs</span>
                                                    </div>

                                                    {/* <div className="testimonial__nav-thumb">
                                                        <img src="assets/img/testimonial/home-3/testi-2.jpg" alt="img not found" />
                                                    </div> */}
                                                </div>
                                            </div>
                                        </SwiperSlide>

                                        

                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-10">
                    <div className="testimonial__video ml-70 fix">
                            <div className="testimonial__thumb-3">
                                <iframe src="https://www.youtube.com/embed/c_K7mx9YWc4" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
                            </div>
                            <div className="testimonial__video-content d-sm-flex">
                                {/* <div className="testimonial__video-icon mr-20 mb-20">
                                        <span><i className="fas fa-check-circle"></i></span>
                                    </div> */}
                                <div className="testimonial__video-text">
                                    <h4>{t('testimonial.What is Edu4all')} ?</h4>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
