import React from 'react';
import FooterThree from '../components/Layout/Footer/FooterStyleThree';
import SignInMain from '../components/SignIn/SignInMain';
import HeaderFour from '../components/Layout/Header/HeaderStyleFour';
import HeaderTwo from '../components/Layout/Header/HeaderStyleTwo';
import HeaderStyleFive from '../components/Layout/Header/HeaderStyleFive';

export default function TermsAndConditions() {


    return (
        <React.Fragment>
            <HeaderFour />
            <main className="mt-100  ">
                <div className="p-4 terms">
                    <p>Welcome to edu4all! These terms and conditions outline the rules and regulations for the use of our website. Please read them carefully.</p>
                    <p>By accessing this website, we assume you accept these terms and conditions. Do not continue to use edu4all if you do not agree to all of the terms and conditions stated on this page.</p>
                    <ol>
                        <li>Intellectual Property Rights</li>
                  
                    <p>The content provided on edu4all is the property of edu4all and its licensors. All materials on this website, including but not limited to text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software, are the exclusive property of edu4all or its content suppliers and protected by international copyright laws.</p>
                    
                        <li>Use of Materials</li>
                   <p>Users are granted limited permission to access and use the materials provided on edu4all solely for their personal and non-commercial use. Users may not reproduce, duplicate, copy, sell, trade, resell, or exploit any portion of the materials for any purpose without the express written permission of edu4all.</p>
                   
                        <li>Prohibited Activities</li>
                    
                    <p>Users may not engage in any of the following activities while using edu4all:</p>
                    <ul>
                        
                        <li>Screenshot or share the materials with anyone else</li> <li>Attempt to circumvent any security measures put in place to protect the materials</li>
                        <li>Open the website on more than one device or in more than one browser tab</li>
                        <li>Use the materials in any way that violates applicable laws or regulations</li>
                        <li>Use the materials for any commercial purpose</li>
                        </ul>
                     
                            <li>User Accounts</li>
                           
                            <p>Users may be required to create an account to access certain materials on edu4all. Users are responsible for maintaining the confidentiality of their account information, including their username and password, and are responsible for all activities that occur under their account.</p>
                         
                                <li>Termination</li>
                            
                                <p>Edu4all reserves the right to terminate a user's access to the website at any time for any reason, including but not limited to violation of these terms and conditions.</p>
                              
                                    <li>Changes to Terms and Conditions</li>
                                
                                    <p>Edu4all reserves the right to modify these terms and conditions at any time. Users will be notified of any changes via email or by a notice on the website.</p>
                                    
                                        <li>Disclaimer of Warranties</li>
                                       
                                        <p>Edu4all provides the materials on this website "as is" and without any warranties, express or implied. We do not warrant that the materials will be error-free, accurate, or complete, or that the website will be free from viruses or other harmful components.</p>
                                        
                                            <li>Limitation of Liability</li>
                                           
                                            <p>In no event shall edu4all be liable for any damages, including but not limited to direct, indirect, incidental, special, or consequential damages, arising out of or in connection with the use of this website or the materials provided on it.</p> 
                                         
                                                <li>Governing Law</li>
                                            </ol>
                                            <p>These terms and conditions shall be governed by and construed in accordance with the laws of [insert governing law]. Any dispute arising out of or in connection with these terms and conditions shall be subject to the exclusive jurisdiction of the courts of [insert jurisdiction].</p>
                                            <p>If you have any questions or concerns about these terms and conditions, please contact us at [insert contact information].</p></div>
            </main>
            <FooterThree />
        </React.Fragment>
    );

}


