import React from 'react';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useUserContext } from '../../store/UserContext';

export default function WhyChoose() {
   const { t } = useTranslation();
   const { userAuth } = useUserContext();

   return (
      <section className="why__area pt-125 pb-80">
         <div className="container">
            <div className="row align-items-center">
               <div className="col-xxl-5 offset-xxl-1 col-xl-5 offset-xl-1 col-lg-6 col-md-8">
                  <div className="why__content pr-50 mt-40">
                     <div className="section__title-wrapper mb-30 wow fadeInLeft" data-wow-delay="0.5s">
                        <span className="section__sub-title">{t('about.Why Choose Us')}</span>
                        <h2 className="section__title">{t('about.Tools for')} <span className="yellow-bg yellow-bg-big">{t('about.Teachers')}<img src="assets/img/shape/yellow-bg.png" alt="img not found" /></span> {t('about.and Learners')}</h2>
                        <p className='mt-20'>{t('about.At Edu4all, we are committed to providing a top-notch learning experience for our students. We have a team of dedicated and experienced educators who create engaging and interactive course material to help students succeed. Our platform offers a user-friendly interface, making it easy for students to navigate and access their course materials.')}</p>
                     </div>
                     <div className="why__btn">
                        <Link to={!userAuth ?"/sign-in":"/courses"} className="e-btn e-btn-3 mr-30 wow fadeInUp" data-wow-delay="1s">Join for Free</Link>

                        
                        <Link to="/about" className="link-btn wow fadeInUp" data-wow-delay="1.5s">Learn More<i className="fas fa-arrow-right "></i>
                           <i className="fas fa-arrow-right"></i>
                        </Link>
                     </div>
                  </div>
               </div>
               <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-8">
                  <div className="why__thumb wow fadeInRight" data-wow-delay="1s">
                     <img src="assets/img/why/why.png" alt="img not found" />
                     <img className="why-green" src="assets/img/why/why-shape-green.png" alt="img not found" />
                     <img className="why-pink" src="assets/img/why/why-shape-pink.png" alt="img not found" />
                     <img className="why-dot" src="assets/img/why/why-shape-dot.png" alt="img not found" />
                     <img className="why-line" src="assets/img/why/why-shape-line.png" alt="img not found" />
                  </div>
               </div>
            </div>
         </div>
      </section>
   );
}