import React from 'react';
import { Link } from 'react-router-dom';

import { subjectArray } from './ValuesGardeSubject.js';
import { useCourseContext } from '../../store/CourseContext.js';

import { useTranslation } from 'react-i18next';

export default function Category() {
    const { t } = useTranslation();

    const { setGradeSubjectFilter } = useCourseContext();

    return (
        <main>
            <section className="category__area pt-120 pb-70 " >
                <div className="container">
                    <div className="row align-items-end ">
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-8">
                            <div className="section__title-wrapper mb-45 wow fadeInLeft" data-wow-delay="0.5s">
                                <h2 className="section__title">{t('general.Choose by')}<span className="yellow-bg yellow-bg-big"> {t('subject.Subject')}<img src="assets/img/shape/yellow-bg.png" alt="img not found" /></span>
                                </h2>
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-4">
                            <div className="category__more mb-50 float-md-end fix wow fadeInRight" data-wow-delay="0.5s">
                                <Link to="/courses" className="link-btn"> View all Courses<i className="fas fa-arrow-right"></i>
                                    <i className="fas fa-arrow-right"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="row wow fadeInUp" data-wow-delay="0.5s">


                        {/*Causing Hydration Error*/}
                        {subjectArray.map((n, index) => {
                            return <div key={index} className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <Link onClick={() => setGradeSubjectFilter({ grade: '', subject: n.subject })} to="/courses">
                                    <div className="category__item mb-30 transition-3 d-flex align-items-center">

                                        <div className="category__icon mr-30">
                                            {n.svg}
                                        </div>
                                        <div className="category__content">
                                            <h4 className="category__title"><Link onClick={() => setGradeSubjectFilter({ grade: '', subject: n.subject })} to="/courses">{t(`subject.${n.subject}`)}</Link></h4>
                                            <p>{t(`general.${n.description}`)}</p>
                                        </div>

                                    </div>
                                </Link>
                            </div>
                        })}



                    </div>
                </div>
            </section>
        </main>
    );

}

