import { Link } from 'react-router-dom';
import React from 'react'

import { useTranslation } from 'react-i18next';


export default function GradeTabItem(props) {
   const { t } = useTranslation();

   let bgc = props.bgColor || 'blue-bg-4'
   return (
      <div className={"grades__item " + bgc}>
         <div className="grades__content">
            <div className="grades__title"><Link to="/courses"> {t(`grade.${props.grade}`)}</Link></div>


            <Link to="/courses" className="link-btn-2">
               <i className="fas fa-arrow-right"></i>
               <i className="fas fa-arrow-right"></i>
            </Link>
         </div>
      </div>
   );
}
