import React from 'react';
import { Link } from 'react-router-dom';

import GradeTabItem from '../Elements/Tab/GradeTabItem.js';

import { gradesArray as grades } from './ValuesGardeSubject';
import { useCourseContext } from '../../store/CourseContext.js';

import { useTranslation } from 'react-i18next';

export default function GradesSection() {
    const { t } = useTranslation();

    const { setGradeSubjectPair } = useCourseContext();

    return (
        <section className="course__area pt-50 pb-50 grey-bg ">
            <div className="container mb-60">
                <div className="row align-items-end">
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-8">
                        <div className="section__title-wrapper mb-60 wow fadeInLeft" data-wow-delay="0.5s">
                            <h2 className="section__title">{t('general.Choose by')}<span className="yellow-bg yellow-bg-big"> {t("grade.Grade")}<img src="assets/img/shape/yellow-bg.png" alt="img not found" /></span> </h2>
                            <p>{t("gradesSection.You don't have to struggle alone, you've got our assistance and help.")}</p>
                        </div>
                    </div>


                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-4">
                        <div className="category__more mb-60 float-md-end fix wow fadeInRight" data-wow-delay="0.5s">
                            <Link to="/courses" className="link-btn"> View all Courses<i className="fas fa-arrow-right"></i>
                                <i className="fas fa-arrow-right"></i>
                            </Link>
                        </div>
                    </div>

                </div>


                {/*Causing Hydration Error*/}
                <div className='d-flex flex-wrap justify-content-around'>
                    {grades.map((n, index) => {
                        return <Link to="/courses" className="wow fadeInUp" data-wow-delay={`${index * 0.3}s`}
                            onClick={() => setGradeSubjectPair({ grade: n.grade, subject: '' })}
                            key={index}>
                            <GradeTabItem grade={n.grade} bgColor={n.active ? n.bgColor : 'grey-bg-dark inactive'} /></Link>
                    })}

                </div>

            </div>
        </section>
    )
};