import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useNavigate } from 'react-router-dom';
import BurgerMenus from './BurgerMenus';
import LanguagePicker from './language/LanguagePicker';
import SignOut from '../../SignIn/SignOut';

import { useTranslation } from 'react-i18next';
import { useUserContext } from '../../../store/UserContext';
import { auth } from '../../../firebase';



//drop shadow fixed
const HeaderFour = () => {
    const { t } = useTranslation();

    const [menuOpen, setMenuOpen] = useState(false)
    const [path, setPath] = useState("")

    const navigate = useNavigate()
    const { userAuth } = useUserContext();


    useEffect(() => {
        setPath(navigate.pathname)
    }, [navigate])

    // Sticky Menu Area start
    useEffect(() => {
        window.addEventListener('scroll', sticky);
        return () => {
            window.removeEventListener('scroll', sticky);
        };
    });

    const sticky = (e) => {
        const header = document.querySelector('.header__area');
        const scrollTop = window.scrollY;
        scrollTop >= 1 ? header.classList.add('sticky') : header.classList.remove('sticky');
    };
    // Sticky Menu Area End

    return (
        <React.Fragment>
            <header>
                <div id="header-sticky" className="header__area header__padding-2 header__shadow">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-2 col-sm-4 col-6">
                                <div className="header__left d-flex">
                                    <div className="logo">
                                        <Link to="/">
                                            <img src="assets/img/logo/logo.png" alt="logo" />
                                        </Link>
                                    </div>
                                    <LanguagePicker />

                                </div>
                            </div>
                            <div className="col-xxl-9 col-xl-9 col-lg-8 col-md-10 col-sm-8 col-6">
                                <div className="header__right d-flex justify-content-end align-items-center">
                                    <div className="main-menu main-menu-2 d-none d-xl-block">
                                        <nav id="mobile-menu">
                                            <ul>
                                                <li><Link to="/">Home</Link></li>
                                                <li><Link to="/my-profile">Profile</Link></li>

                                                <li className="has-dropdown">
                                                    <Link to="/courses">More</Link>
                                                    <ul className="submenu">
                                                        <li><Link to="/courses">All Courses</Link></li>
                                                        <li><Link to="/my-courses">My Courses</Link></li>
                                                        <li><Link to="/redeem">Get a class</Link></li>
                                                        <li><Link to="/shamel">Examens Officiels</Link></li>
                                                        <li><Link to="/about">About</Link></li>
                                                        <li><Link to="/faq">FAQ</Link></li>
                                                        {!auth.currentUser?  <li><Link to="/sign-in">Log in</Link></li>:   <li><Link to="/" ><SignOut>Log out</SignOut></Link></li>}

                                                    </ul>
                                                </li>
                                                <li><Link to="/pricing">Pricing</Link></li>
                                                <li><Link to="/contact">Contact</Link></li>
                                            </ul>
                                        </nav>
                                    </div>
                                    <div className="header__btn header__btn-2 ml-50 d-none d-md-block">
                                        <Link to="/redeem" className="e-btn  e-btn-border">Get a class</Link>
                                    </div>
                                    <div className="header__btn header__btn-2 ml-20 d-none d-md-block">
                                        {!userAuth ?
                                            <Link to="/sign-in" className="e-btn">Sign In</Link>
                                            : <Link to="/" className="e-btn"><SignOut>Log out</SignOut></Link>}
                                    </div>

                                    <div className="sidebar__menu d-xl-none">
                                        <div className="sidebar-toggle-btn ml-30" id="sidebar-toggle" onClick={() => { setMenuOpen(!menuOpen) }}>
                                            <span className="line"></span>
                                            <span className="line"></span>
                                            <span className="line"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <BurgerMenus menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
                <div onClick={() => setMenuOpen(false)} className={menuOpen ? "body-overlay show" : "body-overlay"}></div>
            </header>
        </React.Fragment>
    );
}

export default HeaderFour;