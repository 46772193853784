import React from 'react';
import HeaderTwo from '../components/Layout/Header/HeaderStyleTwo';
import Footer from '../components/Layout/Footer/Footer';
import HomeMain from '../components/Home/HomeMain';

function Index () {


   
        return (
            <React.Fragment>
            <HeaderTwo />
            <HomeMain />
            <Footer />
            </React.Fragment>
        );
    
}


export default Index;

