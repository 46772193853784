import React from 'react'
import Lottie from "lottie-react";
import mainloader from "./main-loader.json"
export default function MainLoader() {
    return (
        <div className='d-flex justify-content-center align-items-center'
            style={{ background: '#ffffff', width: '100%', height: '100%', position: 'fixed', left: 0, top: 0, zIndex: '9' }}>
            <div className='mt-100' style={{ maxWidth: '500px', width: "100%", height: 'auto' }}>
                <h1 style={{ textAlign: 'center' }}>Please wait</h1>
                <img className="logo-black" src="assets/img/logo/logo.png" alt="logo" />

                <Lottie animationData={mainloader} loop={true} />
            </div>
        </div>
    )
}
