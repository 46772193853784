import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom';

import { useUserContext } from '../../../store/UserContext';
import SignOut from '../../SignIn/SignOut';

import { useTranslation } from 'react-i18next';
import { auth } from '../../../firebase';

const BurgerMenus = ({ setMenuOpen, menuOpen }) => {

  const [home, setHome] = useState(false)
  const [courses, setcourses] = useState(false)
  const [more, setMore] = useState(false)
  const [language, setLanguage] = useState(false)

  const navigate = useNavigate()
  const [path, setPath] = useState("");

  const { userAuth } = useUserContext();

  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    setPath(navigate.pathname)
  }, [navigate])



  const openMobileMenu = menu => {

    if (menu == 'home') {
      setHome(!home)
      setcourses(false)
      setMore(false)
      setLanguage(false)
    }
    else if (menu == 'courses') {
      setHome(false)
      setcourses(!courses)
      setMore(false)
      setLanguage(false)
    }
    else if (menu == 'more') {
      setHome(false)
      setcourses(false)
      setMore(!more)
      setLanguage(false)
    }
    else if (menu == 'language') {
      setHome(false)
      setcourses(false)
      setMore(false)
      setLanguage(!language)
    }
  };

  return (
    <div className={menuOpen ? "sidebar__area open" : "sidebar__area"}>
      <div className="sidebar__wrapper">
        <div className="sidebar__close">
          <button className="sidebar__close-btn" id="sidebar__close-btn" onClick={() => setMenuOpen(false)}>
            <span><i className="fas fa-times"></i></span>
            <span>close</span>
          </button>
        </div>
        <div className="sidebar__content">
          <div className="logo mb-40">
            <Link to="/"><img src="assets/img/logo/logo.png" alt="logo" /></Link>
          </div>
          <div className="mm-menu">
            <ul>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/my-profile">My Profile</Link></li>
              <li className={courses ? "has-droupdown active" : "has-droupdown"}>
                <a onClick={() => { openMobileMenu('courses'); }}>Courses</a>
                <ul className={courses ? "sub-menu active" : "sub-menu"}>
                  <li><Link to="/courses">All Courses</Link></li>
                  <li><Link to="/my-courses">My Courses</Link></li>
                  <li><Link to="/redeem">Get a class</Link></li>
                </ul>
              </li>

              <li className={more ? "has-droupdown active" : "has-droupdown"}>
                <a onClick={() => { openMobileMenu('more'); }}>More</a>
                <ul className={more ? "sub-menu active" : "sub-menu"}>
                  <li><Link to="/pricing">Pricing</Link></li>
                  <li><Link to="/shamel">Examens Officiels</Link></li>
                  <li><Link to="/about">About</Link></li>
                  <li><Link to="/faq">FAQ</Link></li>
                  {!auth.currentUser?  <li><Link to="/sign-in">Log in</Link></li>:   <li><Link to="/" ><SignOut>Log out</SignOut></Link></li>}
                </ul>
              </li>

              <li className={language ? "has-droupdown active" : "has-droupdown"}>
                <a onClick={() => { openMobileMenu('language'); }}>Language</a>
                <ul className={language ? "sub-menu active" : "sub-menu"}>
                  <li><Link onClick={() => changeLanguage('en')}>{t('languagePicker.English')}</Link></li>
                  <li><Link onClick={() => changeLanguage('fr')}>{t('languagePicker.French')}</Link></li>

                </ul>
              </li>

            
              <li><Link to="/contact">Contact</Link></li>
            </ul>
          </div>


<div>
<div className="header__btn header__btn-2 d-flex flex-column align-items-center justify-content-center">
                                        <Link to="/redeem" className="e-btn  e-btn-border  mt-20">Get a class</Link>
                                    <div className=" mt-20">
                                        {!userAuth ?
                                            <Link to="/sign-in" className="e-btn">Sign In</Link>
                                            : <Link to="/" className="e-btn"><SignOut>Sign out</SignOut></Link>}
                                    </div>
                                    </div>

</div>
          {/* <div className="sidebar__search p-relative mt-40 ">
            <form action="#">
              <input type="text" placeholder="Search..." />
              <button type="submit"><i className="fas fa-search"></i></button>
            </form>
          </div> */}
          {/* <div className="sidebar__cart mt-30">
                  <a to="#">
                      <div className="header__cart-icon">
                        <svg viewBox="0 0 24 24">
                            <circle className="st0" cx="9" cy="21" r="1"/>
                            <circle className="st0" cx="20" cy="21" r="1"/>
                            <path className="st0" d="M1,1h4l2.7,13.4c0.2,1,1,1.6,2,1.6h9.7c1,0,1.8-0.7,2-1.6L23,6H6"/>
                        </svg>
                      </div>
                      <span className="cart-item">2</span>
                  </a>
                </div> */}
        </div>
      </div>
    </div>
  );
}

export default BurgerMenus;