import { httpsCallable } from "firebase/functions";
import { useState, useCallback } from 'react';
import { auth, functions } from "../firebase";
//import { getCookie, setCookie } from "../utils/cookieUtils";
import { useUserContext } from "./UserContext";
import Cookies from "js-cookie";



export const ValidateVerificationCode = async (userData, setVerificationCodeExists) => {
  // const [verificationCodeExists, setVerificationCodeExists] = useState(false);

  const FIVE_DAYS_IN_MS = 432000000; // 5 days in milliseconds
  const now = new Date().getTime()
const FIVE_MINUTES_IN_MS=5 * 60 * 1000

 async function handleUpdateUserBrowserCode(){
// generte a new number save it in the db
const result = await httpsCallable(functions, 'updateUserBrowserCode')({userAgent: navigator.userAgent// navigator.userAgent
}
  );
const randomNumber = result.data;
///console.log(' browser verififcation created',`BrowserVerificationCode-${auth.currentUser.uid}`, randomNumber)

Cookies.set(`BrowserVerificationCode-${auth.currentUser.uid}`, randomNumber, { expires: 5 })
//setCookie(`BrowserVerificationCode-${auth.currentUser.uid}`, randomNumber, now + FIVE_DAYS_IN_MS); // set HttpOnly cookie
setVerificationCodeExists(true);
  }

  try {
    if (userData) {

      const { browserVerification ,createdAt} = userData;
        //console.log('browser verfifcation checked ', now,browserVerification.expirationDate.toDate().getTime())
//if account was freshly created less than 10min ago
        if( (now - FIVE_MINUTES_IN_MS) < createdAt.toDate().getTime() && !browserVerification){
          setTimeout(() => {
            console['log']('account locked at', new Date())
            handleUpdateUserBrowserCode();
          }, FIVE_MINUTES_IN_MS-10); // run after 10 min
          console['log']('account checked the first time at', new Date())
          setVerificationCodeExists(true);
          return
        }

      if (browserVerification) {
        

        if (now < browserVerification.expirationDate.toDate().getTime()) {
          // if user browserVerification in database has not expired 

          const verificationCodeFromCookie= Cookies.get(`BrowserVerificationCode-${auth.currentUser.uid}`)
         // const verificationCodeFromCookie = getCookie(`BrowserVerificationCode-${auth.currentUser.uid}`);
          //console.log('browser verfifcation from cookie ',`BrowserVerificationCode-${auth.currentUser.uid}`, verificationCodeFromCookie,)

          // if it matchs the one in cookie
          if (verificationCodeFromCookie && verificationCodeFromCookie === browserVerification.number) {
            setVerificationCodeExists(true);
          } else {
            setVerificationCodeExists(false);
          }
        }
        else {
          handleUpdateUserBrowserCode()
        }
      }
      else {
        // generte a new number save it in the db
        handleUpdateUserBrowserCode()

      }
    }


  } catch (error) {
    console.log(error);
  }


  return null
}





