import { confirmPasswordReset, signInWithEmailAndPassword, verifyPasswordResetCode } from 'firebase/auth';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import MiniLoader from '../../../utils/MiniLoader';
import { auth as auth2 } from '../../../firebase';
import { toast } from 'react-toastify';

export default function ResetPassword({auth, actionCode, continueUrl=null, lang=null}) {
 useEffect(() => {
    if(auth2.currentUser)  auth2.signOut()
 }, [])
 
  
  const [error, setError] = useState('');
    const [loader, setLoader] = useState(false);
    const [userData, setUserData] = useState({
  
        password: '',
        rePassword: '',
    });

    const navigate = useNavigate();

    const onChange = (e) => {
        setError('');
        setUserData({ ...userData, [e.target.name]: e.target.value });
    };

    const isPasswordStrong = (password) => {
        const errors = [];
        if (!/(?=.*[A-Z])/.test(password)) {
            errors.push("one uppercase letter");
        }
        if (!/(?=.*[a-z])/.test(password)) {
            errors.push("one lowercase letter");
        }
        if (!/(?=.*\d)/.test(password)) {
            errors.push("one number");
        }
        if (!/(?=.*[@$!%*?&])/.test(password)) {
            errors.push("one special character (@, $, !, %, *, ?, or &)");
        }
        if (password.length < 6) {
            errors.push("6 characters");
        }
        if (errors.length === 0) {
            return null;
        }
        if (errors.length === 1) {
            return `Your password needs to contain at least ${errors[0]}.`;
        }
        const lastError = errors.pop();
        return `Your password needs to contain at least ${errors.join(", ")} and ${lastError}.`;
    }





    


    
function handleResetPassword(auth, actionCode,newPassword, continueUrl=null, lang=null) {
    // Localize the UI to the selected language as determined by the lang
    // parameter.
  
    // Verify the password reset code is valid.
    verifyPasswordResetCode(auth, actionCode).then((email) => {
      const accountEmail = email;
  
      // TODO: Show the reset screen with the user's email and ask the user for
      // the new password.
    
  
      // Save the new password.
      confirmPasswordReset(auth, actionCode, newPassword).then((resp) => {
        // Password reset has been confirmed and new password updated.
  
        // TODO: Display a link back to the app, or sign-in the user directly
        // if the page belongs to the same domain as the app:
        
  
        // TODO: If a continue URL is available, display a button which on
        // click redirects the user back to the app via continueUrl with
        // additional state determined from that URL's parameters.

        setLoader(true);
      
signInWithEmailAndPassword(auth,accountEmail, newPassword).then(()=>{


    setLoader(false);
toast.success("Password updated successfully")
        navigate('/')

}).catch((error) => {
        // Error occurred during confirmation. The code might have expired or the
        // password is too weak.

        setLoader(false);
        setError(`Error ${error}`)
        navigate('/')
      });
     
        
      }).catch((error) => {
        // Error occurred during confirmation. The code might have expired or the
        // password is too weak.

        setLoader(false);
        setError("Invalid or expired action code, or Weak Password Try again later")
      });
    }).catch((error) => {
      // Invalid or expired action code. Ask user to try to reset the password
      // again.


      setLoader(false);
      setError("Invalid or expired action code. Try again later")
    });
  }
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);

    const passwordErrors = isPasswordStrong(userData.password) ||[];

    if (userData.password != '' && userData.rePassword != '') {

        if (userData.password === userData.rePassword) {

            if (passwordErrors.length === 0) {

                handleResetPassword(auth,actionCode, userData.password  );

            } else {
                // password is not strong
                setLoader(false);
                setError(passwordErrors);
            }

        } else {
            setLoader(false);
            setError("Passwords do not match")
        }

    } else {
        setLoader(false);
        setError("Please fill out all fields")
    }
}




    return (
        
        <main>
            <section className="signup__area po-rel-z1 pt-100 pb-145">
                <div className="sign__shape">
                    <img className="man-1" src="assets/img/icon/sign/man-3.png" alt="img not found" />
                    <img className="man-2 man-22" src="assets/img/icon/sign/man-2.png" alt="img not found" />
                    <img className="circle" src="assets/img/icon/sign/circle.png" alt="img not found" />
                    <img className="zigzag" src="assets/img/icon/sign/zigzag.png" alt="img not found" />
                    <img className="dot" src="assets/img/icon/sign/dot.png" alt="img not found" />
                    <img className="bg" src="assets/img/icon/sign/sign-up.png" alt="img not found" />
                    <img className="flower" src="assets/img/icon/sign/flower.png" alt="img not found" />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-8 offset-xxl-2 col-xl-8 offset-xl-2">
                            <div className="section__title-wrapper text-center mb-55">
                                <h2 className="section__title mt-25">Reset your Password</h2>

                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-6 offset-xxl-3 col-xl-6 offset-xl-3 col-lg-8 offset-lg-2">
                            <div className="sign__wrapper white-bg">
                              
                                <div className="sign__form">
                                    <form onSubmit={handleSubmit}>
                                      
                                        <div className="sign__input-wrapper mb-25">
                                            <h5>Password</h5>
                                            <div className="sign__input">
                                                <input
                                                    type="password"
                                                    placeholder="Password"
                                                    name='password'
                                                    value={userData.password}
                                                    onChange={onChange}
                                                    required />
                                                <i className="fas fa-lock"></i>
                                            </div>
                                        </div>
                                        <div className="sign__input-wrapper mb-10">
                                            <h5>Re-Password</h5>
                                            <div className="sign__input">
                                                <input
                                                    type="password"
                                                    placeholder="Re-Password"
                                                    name='rePassword'
                                                    value={userData.rePassword}
                                                    onChange={onChange}
                                                    required />
                                                <i className="fas fa-lock"></i>
                                            </div>
                                        </div>
                                        <p className='text-danger'>{error}</p>
                                        <button className="e-btn w-100" type='submit'> <span></span>{!loader ? 'Reset Password' : <MiniLoader />}</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}


