import React, { useEffect } from 'react'
import { doc, onSnapshot } from "firebase/firestore"

import { db, auth } from '../firebase.js';
import { useUserContext } from './UserContext.js';
import {ValidateVerificationCode} from './BrowserVerification';


export default function CheckUserInDatabase() {


    const {setUserData,  verificationCodeExists, setVerificationCodeExists} = useUserContext();



    const docRef = doc(db, "users", auth.currentUser.uid);
    useEffect(() => {

        // getDoc_Cached(docRef).then((res)=>{console.log('cached user',res)})

        onSnapshot(docRef, (snapshot) => {
           // console.log('user data fetched', snapshot.data())

            if (snapshot.exists()) {
                setUserData({...snapshot.data(),uid:snapshot.id});
                ValidateVerificationCode(snapshot.data(),setVerificationCodeExists);
            }


        })
    }, []
    )

    return null

}

