import React from 'react';

import { Tabs, Tab, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';



export default function PriceTab() {
    const { t } = useTranslation();

    return (
        <div>
            <section className="price__area pt-60 pb-130 p-relative">
                <div className="sign__shape">
                    <img className="man-1" src="assets/img/icon/sign/man-1.png" alt="img not found" />
                    <img className="man-2 man-22" src="assets/img/icon/sign/man-2.png" alt="img not found" />
                    <img className="circle" src="assets/img/icon/sign/circle.png" alt="img not found" />
                    <img className="zigzag" src="assets/img/icon/sign/zigzag.png" alt="img not found" />
                    <img className="dot" src="assets/img/icon/sign/dot.png" alt="img not found" />
                    <img className="bg" src="assets/img/icon/sign/sign-up.png" alt="img not found" />
                    <img className="flower" src="assets/img/icon/sign/flower.png" alt="img not found" />
                </div>
                <Tabs>

                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-4 offset-xxl-4">
                                <div className="section__title-wrapper mb-60 text-center">
                                    <h2 className="section__title">Simple <br /> {t('priceTab.All Inclusive')}<span className="yellow-bg yellow-bg-big"> {t('priceTab.Pricing')}<img src="assets/img/shape/yellow-bg.png" alt="img not found" /></span></h2>
                                    <p>{t('priceTab.No contracts. No surprise fees.')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xxl-12">
                                <div className="price__tab-btn text-center mb-50">
                                    <div className="nav-tabs">
                                        <TabList>
                                            <Tab><button className="nav-link" id="nav-monthly-tab" type="button">{t('priceTab.Single Course')}</button></Tab>
                                            <Tab><button className="nav-link" id="nav-annually-tab" type="button">{t('priceTab.Two Courses')}</button></Tab>
                                            <Tab><button className="nav-link" id="nav-annually-tab" type="button">{t('priceTab.Three Courses')}</button></Tab>
                                            <Tab><button className="nav-link" id="nav-annually-tab" type="button">{t('priceTab.Four Courses')}</button></Tab>
                                        </TabList>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">


                            <div className="col-md-6 mx-auto">
                                <div className="price__tab-content">
                                    <TabPanel>
                                        <div className="row">



                                            <div className="col-12">
                                                <div className="price__item white-bg mb-30 p-relative">
                                                    <div className="price__head">
                                                        <h3>{t('priceTab.Single')}</h3>
                                                        <p>{t('priceTab.A great option for beginners')}</p>
                                                    </div>
                                                    <div className="price__tag mb-25">
                                                        <h4>$13<span> / {t('priceTab.annually')} / <span  style={{fontWeight:'bold',color:'white',background:'#8007e6',borderRadius:'20px',padding:'0 5px'}}> {t(`grade.${"Grade 9"}`)}</span> </span></h4>
                                                    </div>

                                                    <div className="price__tag mb-25">
                                                        <h4>$20<span> / {t('priceTab.annually')}/ <span  style={{fontWeight:'bold',color:'white',background:'#dd246e',borderRadius:'20px',padding:'0 5px'}}> {t(`grade.${"Grade 12"}`)}</span></span></h4>
                                                    </div>
                                                    <div className="price__features mb-40">
                                                        <ul>
                                                            <li><i className="fas fa-check"></i>{t('priceTab.Course Discussions')}</li>
                                                            <li><i className="fas fa-check"></i>{t('priceTab.Content Library')}</li>
                                                            <li><i className="fas fa-check"></i>{t('priceTab.1-hour Mentorship')}</li>
                                                        </ul>
                                                    </div>
                                                    <Link to="/contact" className="e-btn e-btn-4">Get Started</Link>
                                                </div>
                                            </div>

                                        </div>
                                    </TabPanel>


                                    <TabPanel>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="price__item white-bg mb-30 p-relative">
                                                    <div className="price__head">
                                                        <h3>{t('priceTab.Double')}</h3>
                                                        <p>{t('priceTab.Best suited for those seeking variety')}</p>
                                                    </div>
                                                    <div className="price__tag mb-25">
                                                        <h4>$25<span> / {t('priceTab.annually')} / <span  style={{fontWeight:'bold',color:'white',background:'#8007e6',borderRadius:'20px',padding:'0 5px'}}> {t(`grade.${"Grade 9"}`)}</span> </span></h4>
                                                    </div>

                                                    <div className="price__tag mb-25">
                                                        <h4>$40<span> / {t('priceTab.annually')}/ <span  style={{fontWeight:'bold',color:'white',background:'#dd246e',borderRadius:'20px',padding:'0 5px'}}> {t(`grade.${"Grade 12"}`)}</span></span></h4>
                                                    </div>
                                                    <div className="price__features mb-40">
                                                        <ul>
                                                            <li><i className="fas fa-check"></i>{t('priceTab.Course Discussions')}</li>
                                                            <li><i className="fas fa-check"></i>{t('priceTab.Content Library')}</li>
                                                            <li><i className="fas fa-check"></i>{t('priceTab.1-hour Mentorship')}</li>
                                                        </ul>
                                                    </div>
                                                    <Link to="/contact" className="e-btn e-btn-4">Get Started</Link>
                                                </div>
                                            </div>

                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="price__item white-bg mb-30 p-relative">
                                                    <div className="price__head">
                                                        <h3>{t('priceTab.Triple')}</h3>
                                                        <p>{t('priceTab.Best for comprehensive learning')}</p>
                                                    </div>
                                                    <div className="price__tag mb-25">
                                                        <h4>$36<span> / {t('priceTab.annually')} / <span  style={{fontWeight:'bold',color:'white',background:'#8007e6',borderRadius:'20px',padding:'0 5px'}}> {t(`grade.${"Grade 9"}`)}</span> </span></h4>
                                                    </div>

                                                   
                                                    <div className="price__features mb-40">
                                                        <ul>
                                                            <li><i className="fas fa-check"></i>{t('priceTab.Course Discussions')}</li>
                                                            <li><i className="fas fa-check"></i>{t('priceTab.Content Library')}</li>
                                                            <li><i className="fas fa-check"></i>{t('priceTab.1-hour Mentorship')}</li>
                                                        </ul>
                                                    </div>
                                                    <Link to="/contact" className="e-btn e-btn-4">Get Started</Link>
                                                </div>
                                            </div>

                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="row">

                                            <div className="col-12">
                                                <div className="price__item white-bg mb-30 p-relative">
                                                    <div className="price__offer">
                                                        <span>{t('priceTab.Best Value')}</span>
                                                    </div>
                                                    <div className="price__head">
                                                        <h3>{t('priceTab.Quadriple')}</h3>
                                                        <p>{t('priceTab.The ultimate learning package')}</p>
                                                    </div>
                                                    <div className="price__tag mb-25">
                                                        <h4>$46<span> / {t('priceTab.annually')} / <span  style={{fontWeight:'bold',color:'white',background:'#8007e6',borderRadius:'20px',padding:'0 5px'}}> {t(`grade.${"Grade 9"}`)}</span> </span></h4>
                                                    </div>
                                                    <div className="price__features mb-40">
                                                        <ul>
                                                            <li><i className="fas fa-check"></i>{t('priceTab.Course Discussions')}</li>
                                                            <li><i className="fas fa-check"></i>{t('priceTab.Content Library')}</li>
                                                            <li><i className="fas fa-check"></i>{t('priceTab.1-hour Mentorship')}</li>
                                                        </ul>
                                                    </div>
                                                    <Link to="/contact" className="e-btn e-btn-border">Get Started</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>

                                </div>
                            </div>


                        </div>
                    </div>
                </Tabs>
            </section>
        </div>
    )
};