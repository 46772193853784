import React from 'react';
import Footer from '../components/Layout/Footer/Footer';
import CourseGridMain from '../components/CourseGrid/CourseGridMain';
import HeaderFive from '../components/Layout/Header/HeaderStyleFive';

function CourseGrid() {

  
        return (
            <React.Fragment>
            <HeaderFive />
            <CourseGridMain />
            <Footer />
            </React.Fragment>
        );
    
}


export default CourseGrid;

