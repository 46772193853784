import React from 'react';
import { Link } from 'react-router-dom';
import AppConfig from '../../../Appconfig';
import version from "../../versionDev/version.json";

const FooterBottom = () => {
    return (
        <div className="footer__bottom">
            <div className="container">
                <div className="row">
                <div className="col-xxl-12">
                    <div className="footer__copyright text-center">
                        <p>© {new Date().getFullYear()} edu4all, All Rights Reserved. Design By <Link to="https://moondev.solutions">MoonDev</Link></p>
                        <p className="text-center">{ `version ${version.buildDate|| AppConfig.version || ' 1.0.0' }` }  </p>
                    </div>
                </div>
                </div>
            </div>
        </div>
    );
}

export default FooterBottom;