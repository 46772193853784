import React from 'react'
import { useEffect } from 'react';
import { toast } from 'react-toastify';

export default function useOnlineStatus() {


      
        useEffect(() => {
          const handleOffline = () => toast.warn("User is Offline");
          const  handleOnline= () =>  toast.success("User is back online");
      
          window.addEventListener('online', handleOnline);
          window.addEventListener('offline', handleOffline);
      
          return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
          };
        }, []);


  return null
}
