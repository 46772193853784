

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { auth } from '../../firebase';
import ResetPassword from './ResetPassword/ResetPassword';
import VerifyEmail from './VerifyEmail/VerifyEmail';
import RecoverEmail from './RecoverEmail/RecoverEmail';

const DisplayNoReply = () => {
  const location = useLocation();
  const [mode, setMode] = useState(null);
  const [actionCode, setActionCode] = useState(null);
  const [continueUrl, setContinueUrl] = useState(null);
  const [lang, setLang] = useState('en');


  const navigate = useNavigate();

  useEffect(() => {
    const getParameterByName = (name, url) => {
      if (!url) url = window.location.href;
      name = name.replace(/[\[\]]/g, '\\$&');
      const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
      const results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    const modeParam =getParameterByName('mode', location.search);
    const actionCodeParam = getParameterByName('oobCode', location.search);
    const continueUrlParam = getParameterByName('continueUrl', location.search);
    const langParam = getParameterByName('lang', location.search) || 'en';

    setMode(modeParam);
    setActionCode(actionCodeParam);
    setContinueUrl(continueUrlParam);
    setLang(langParam);
  }, [location.search]);

  // Render components based on mode
  let componentToRender = null;
  switch (mode) {
    case 'resetPassword':
      componentToRender = <ResetPassword auth={auth} actionCode={actionCode} continueUrl={continueUrl} lang={lang} />;
      break;
    case 'recoverEmail':
      componentToRender = <RecoverEmail auth={auth} actionCode={actionCode} continueUrl={continueUrl} lang={lang} />;
      break;
    case 'verifyEmail':
      componentToRender = <VerifyEmail auth={auth} actionCode={actionCode} continueUrl={continueUrl} lang={lang} />;
      break;
    default:
      navigate('/')
      // Error: invalid mode.

   
      console.error('Invalid mode:', mode);
  }

  return componentToRender;
};

export default DisplayNoReply;

