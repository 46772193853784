import React from 'react';
import './index.css';
import './pages/index.scss';

import ReactDOM from "react-dom/client";
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import * as swr from './serviceWorkerRegistration'
import { UserContextProvider } from './store/UserContext'

import { CourseProvider } from './store/CourseContext';

import './i18n';


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <CourseProvider>
        <UserContextProvider>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </UserContextProvider>
    </CourseProvider>
);

swr.register();