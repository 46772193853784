import { createContext, useCallback, useContext, useState } from 'react';

const CourseContext = createContext({
    grade: null,
    subject: null,    
    setGradeSubjectPair:()=>{},

  courses: [],
  setCourses: () => { },

  chapters: [],
  setChapters: () => { },

  suppExercices: [],
  setSuppExercices: () => { },

  singleChapter:null,
  setSingleChapter: () => { },

  chapterActivities: [],
  setChapterActivities: () => { },

  singleActivity: null,
  setSingleActivity: () => { },

  chapterSimulations: [],
  setChapterSimulations: () => { },

    chapterMCQ: null,
    setChapterMCQ:()=>{},


    setGradeSubjectPair:()=>{},


    subjectFilter:null,
    gradeFilter:null,
    setGradeSubjectFilter:()=>{},

  });




export const CourseProvider = ({ children }) => {
  const [grade, setGrade] = useState('');
  const [subject, setSubject] = useState('');
  const [courses, setCourses] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [suppExercices, setSuppExercices] = useState([]);
  const [singleChapter, setSingleChapterState] = useState(null);
  const [chapterActivities, setChapterActivities] = useState([]);
  const [singleActivity, setSingleActivity] = useState(null);
  const [chapterSimulations, setChapterSimulations] = useState([]);
  const [chapterMCQ, setChapterMCQ] = useState([]);



  const [gradeFilter, setGradeFilter] = useState('');
  const [subjectFilter, setSubjectFilter] = useState('');

  const setGradeSubjectFilter = useCallback(({subject,grade}) => {
    if (true//grade && subject
      ) {
      setGradeFilter(grade);
      setSubjectFilter(subject);
      setChapters([]);
      setSuppExercices([]);
      setSingleActivity({})
      setChapterActivities([]);
      setChapterSimulations([]);
      setSingleChapter({});
      setChapterMCQ([]);
    } else {
      console.error('setting subject grade pair');
    }      console.log(' subject grade filter',subject,grade);


  }, [grade, subject]);

  function setSingleChapter(chapter) {

    setSingleChapterState(chapter);
    setChapterSimulations([])
    setChapterActivities([])
    setSuppExercices([])
    setChapterMCQ([])
  }

const setGradeSubjectPair = useCallback(({subject,grade}) => {
    if (true//grade && subject
      ) {
      setGrade(grade);
      setSubject(subject);
      setChapters([]);
      setSuppExercices([]);
      setSingleActivity({})
      setChapterActivities([]);
      setChapterSimulations([]);
      setSingleChapter({});
      setChapterMCQ([]);
    } else {
      console.error('setting subject grade pair');
    } console.log(' subject grade pair', subject, grade);


  }, [grade, subject]);



  function findCourseByGradeSubject(  grade,subject) {
    // Use the find method to search for the object
    const foundObject = courses.find(item => item.subject === subject && item.grade === grade);
  
    // Return the found object or null if not found
    return foundObject || null;
  }


 




  const contextValues = {
    grade,
    subject,
    setGradeSubjectPair: setGradeSubjectPair,


    courses,
    setCourses,

    chapterActivities,
    setChapterActivities,

    singleChapter,
    setSingleChapter,

    singleActivity,
    setSingleActivity,

    chapterSimulations,
    setChapterSimulations,

    chapterMCQ,
    setChapterMCQ,

    chapters,
    setChapters,

    suppExercices,
    setSuppExercices,

    subjectFilter,
    gradeFilter,
    setGradeSubjectFilter:setGradeSubjectFilter,


    findCourseByGradeSubject,

  };

  return (
    <CourseContext.Provider value={contextValues}>
      {children}
    </CourseContext.Provider>
  );
};

export const useCourseContext = () => {
  const context = useContext(CourseContext);
  if (context === null) {
    throw new Error('useCourseContext must be used within a CourseProvider');
  }
  return context;
};