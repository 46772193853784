import { httpsCallable } from 'firebase/functions';
import React, { useEffect, useState } from 'react'
import { auth, functions } from '../firebase';
import { useUserContext } from './UserContext';
import useInterval from '../utils/SetInterval';

export function ValidateUserClaims() {
const [isEmailVerified, setIsEmailVerified] = useState(false)
    const { setUserAuth,setUserClaims } = useUserContext();
    // const subscribeToCourse = httpsCallable(functions, 'getUserClaims');

    useEffect(() => {
        auth.onAuthStateChanged(async(authUser) => {
            if (authUser) {
                setUserAuth(authUser);

                // subscribeToCourse().then((Result) => {
                //     setUserClaims(Result)
                // }).catch((e) => console.log(e));
                const idTokenResult = await authUser.getIdTokenResult();
          
                const isAdmin =!! idTokenResult.claims.admin ;
                setUserClaims({isAdmin:isAdmin})
           
            } else {
                console.log("no user logged in");
                setUserAuth(null)

            }
        })




    }, [])

   

    //     useInterval(()=>{
    
    //         console.log('waiting for user email verification',auth.currentUser)
    // if(auth.currentUser.emailVerified)setIsEmailVerified(true)
    
    //     },isEmailVerified?0:1000)
    



}
