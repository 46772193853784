import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { useUserContext } from '../../store/UserContext';



function UnverifiedBrowserMain(){
    const {userData}=useUserContext();
    return (
        <main>
            <section className="error__area pt-200 pb-200">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-8 offset-xxl-2 col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
                            <div className="error__item text-center">
                                <div className="error__thumb mb-45">
                                <img src="assets/img/error/error.png" alt="img not found"/>
                                </div>
                                <div className="error__content">
                                <h4 className="error__title">Oops,Site Already Active!</h4>
                                <p>{`Account is already opened on another browser: ${userData?.browserVerification.userAgent}`}</p>
                                <p>Cannot change your browser before <b><u>{`${userData?.browserVerification.expirationDate.toDate().toString().substring(0,21)}`}</u></b></p>
                                <div className="d-flex justify-content-center">

                                </div>
                                <Link to="/" className="e-btn e-btn-3 e-btn-4">Back To Home</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default UnverifiedBrowserMain;