import { child, get } from "firebase/database";
import { getDoc, getDocs, query } from "firebase/firestore";

const cache = {};


export async function get_cached(child) {
  const refKey = child.toString();

  if (cache[refKey]) {
    console.log(' get_cached called from cache')

    return Promise.resolve(cache[refKey]);
  }


  return get(child).then((snapshot) => {
    console.log(' get_cached called from database')

    if (snapshot.exists()) {
        const data = snapshot.val();
        cache[refKey] = data;
        return data;
    } else {
      console.log("No data available");
      return null;
    }
  }).catch((error) => {
    console.error(error);
  });
  


//   return ref.once("value").then((snapshot) => {
//     const data = snapshot.val();
//     cache[refKey] = data;
//     return data;
//   });
}







export async function getDoc_Cached(docRef) {
  const queryKey = JSON.stringify(docRef);
//   console.log('queryKey',queryKey)

  if (cache[queryKey]) {
    console.log('getDoc_Cached called from cache')
    return Promise.resolve(cache[queryKey]);
  }


  const docSnap = await getDoc(docRef);
  console.log(' getDoc_Cached called from database')

  if (docSnap.exists()) {
    const data={ ...docSnap.data(),id: docSnap.id}
    cache[queryKey] = data;
    return data;
  } else {
    // doc.data() will be undefined in this case
    return null
  }

  
   




}
export async function getDocs_Cached(query) {
    
  const queryKey = JSON.stringify(query);

  if (cache[queryKey]) {
    return Promise.resolve(cache[queryKey]);
  }

  const snapshot = await getDocs(query);
    const data = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
    }));
    cache[queryKey] = data;
    return data;
}

