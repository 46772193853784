import React, { useCallback, Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import WOW from 'wowjs';
import CheckUserInDatabase from './store/CheckUserInDatabase';
import { useUserContext } from './store/UserContext.js';
import { ValidateUserClaims } from './store/ValidateUserClaims';
import ScrollToTop from './utils/ScrollToTop.js';
import MainLoader from './utils/PageLoader/MainLoader';
import UnverifiedBrowser from './components/UnverifiedBrowser/UnverifiedBrowser';
import useOnlineStatus from './hooks/useOnlineStatus';

import TermsAndConditions from './pages/terms-and-conditions';
import Index from './pages/home';
import SignIn from './pages/sign-in';
import CourseGrid from './pages/courses';
import { auth } from './firebase';
import NoReplyPage from './pages/noreply.js';

const AboutPage = lazy(() => import('./pages/about'));
const MyCourses = lazy(() => import('./pages/my-courses'));
const ActivityFullView = lazy(() => import('./pages/activity-full-view'));
const ChapterFullView = lazy(() => import('./pages/chapter-full-view'));
const CourseFullView = lazy(() => import('./pages/course-full-view'));
const Contact = lazy(() => import('./pages/contact'));
const Error = lazy(() => import('./pages/error'));
const Faq = lazy(() => import('./pages/faq'));
const Pricing = lazy(() => import('./pages/pricing'));
const Redeem = lazy(() => import('./pages/redeem'));
const Shamel = lazy(() => import('./pages/shamel'));
const SignUp = lazy(() => import('./pages/sign-up'));
const ForgotPassword = lazy(() => import('./pages/forgot-password.js'));
const PhoneVerification = lazy(() => import('./pages/phone-verification.js'));
const EmailVerification = lazy(() => import('./pages/email-verification.js'));
const MyProfile = lazy(() => import('./pages/my-profile'));

function App() {
  const {  userClaims, userData, verificationCodeExists } = useUserContext();

  const wow = useCallback(() => {
    new WOW.WOW({
      live: false
    }).init();
  }, []);
  wow();

  //sets user auth in context
  ValidateUserClaims();

  useOnlineStatus();


  //console.log('auth      ',auth.currentUser)
  function isSignedIn(component) {
    return auth.currentUser ? component : <SignIn />;
    //=signin;
  }

  function isPhoneVerified(component) {

   
    return isSignedIn(Boolean(auth.currentUser?.phoneNumber  || auth.currentUser?.emailVerified) ? component : <PhoneVerification />);
  }

  

  function isEmailOrPhoneVerified(component) {

  //  console.log('auth',auth.currentUser)
    return isSignedIn((auth.currentUser?.phoneNumber!==null  || auth.currentUser?.emailVerified===true )? component : <EmailVerification />);
   
  }

  function isActiveUserOnBrowser(component) {
    return userClaims?.isAdmin
      ? component
      : isEmailOrPhoneVerified(verificationCodeExists ? component : <UnverifiedBrowser />);
  }

  return (
    <div>
      {auth.currentUser  && <CheckUserInDatabase />}
      {auth.currentUser  &&  !( userData || userClaims?.isAdmin) && <MainLoader />}


{/* { console.log(' context user auth ', userAuth,
              ' auth ',auth.currentUser,
              " Auth claims context ",userClaims,
             )} */}


      <Suspense fallback={<MainLoader />}>
        <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<Index />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/activity-full-view" element={isActiveUserOnBrowser(<ActivityFullView />)} />
          <Route path="/chapter-full-view" element={isActiveUserOnBrowser(<ChapterFullView />)} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/course-full-view" element={isActiveUserOnBrowser(<CourseFullView />)} />
          <Route path="/courses" element={<CourseGrid />} />
          <Route path="/error" element={<Error />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/redeem" element={<Redeem />} />
          <Route path="/my-courses" element={isActiveUserOnBrowser(<MyCourses />)} />
          <Route path="/shamel" element={<Shamel />} />
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/terms-conditions" element={<TermsAndConditions />} />
          <Route path="/phone-verification" element={<PhoneVerification />} />
          <Route path="/email-verification" element={<EmailVerification />} />
          <Route path="/noreply" element={<NoReplyPage />} />
          <Route path="/my-profile" element={isActiveUserOnBrowser(<MyProfile />)} />
          <Route path="*" element={<Index />} />
        </Routes>
      </Suspense>

      <ToastContainer />

      <div id='recaptcha-container'></div>
    </div>
  );
}

export default App;
