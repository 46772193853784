import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
import { getDatabase } from "firebase/database";

import { getMessaging, getToken, isSupported, onMessage } from "firebase/messaging";



const firebaseConfig = {
  apiKey: `${process.env.REACT_APP_API_KEY}`,
  authDomain: `${process.env.REACT_APP_AUTH_DOMAIN}`,
  databaseURL: `${process.env.REACT_APP_DATABASE_URL}`,
  projectId: `${process.env.REACT_APP_PROJECT_ID}`,
  storageBucket:`${ process.env.REACT_APP_STORAGE_BUCKET}`,
  messagingSenderId: `${process.env.REACT_APP_MESSAGING_SENDER_ID}`,
  appId: `${process.env.REACT_APP_APP_ID}`,
};

// Now you can use the firebaseConfig object in your React components

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseapp = initializeApp(firebaseConfig);


export const messaging = null//isSupported() ? getMessaging(firebaseapp) : null;
export const auth = getAuth(firebaseapp);
export const db = getFirestore(firebaseapp);
export const storage = getStorage(firebaseapp);
export const functions = getFunctions(firebaseapp);

export const rtdb = getDatabase(firebaseapp);

/*
export const requestToken = async (setTokenFound) => {
  
  return getToken(messaging, { vapidKey:'BKT7IHkLPWPpRXGxV03z2fYrJOmU4RqE7s_nN3pxbp0FVo3hZgzsGdTSdtqUOx1GW0cfkZSf1dk8WCO-PWOj_tE' })
      .then((currentToken) => {
        if (currentToken) {
          setTokenFound({state:true , token:currentToken,});
          console.log('current token for client: ', currentToken);
          console.log( navigator.clipboard.writeText(currentToken));
          
          // Perform any other neccessary action with the token
        } else {
          setTokenFound({state:false, token:'',});
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
};
*/

export const requestNotificationToken = async () => {
  let result = getToken(messaging, { vapidKey: 'BOOwyNiNdrRcCqqlM3dstzoqFSClB8t-nD8bYVp5_GHP6lnXMgdVRmDaRQL11qnTjbzy0AYz1gqYeD3Z961kB0Y' })
    .then((currentToken) => {
      if (currentToken) {
        //  console.log('current token for client2: ', currentToken);
        return currentToken
        // console.log('token copied to clipboard',  navigator.clipboard.writeText(currentToken));     
        // Perform any other neccessary action with the token
      } else {
        console.log('No registration token available. Request permission to generate one.');
        return null;
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      return null;
    });
  return result
}



export const onMessageListener = () =>
  new Promise((resolve) => {
    isSupported() && onMessage(messaging, (payload) => {
      //console.log("payload", payload)
      resolve(payload);
    })
  }).catch((e) => console.log(e));