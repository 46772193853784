import React, { useEffect, useState, useCallback } from 'react';
import 'react-tabs/style/react-tabs.css';

import CourseList from '../CourseDetails/CourseList';
import { rtdb } from '../../firebase';
import { ref, child } from "firebase/database";
import { useCourseContext } from '../../store/CourseContext';
import { get_cached } from '../../store/QueryCache';

import { useTranslation } from 'react-i18next';
import Loader from '../../utils/Loader';


export default function CourseGridTab() {
    const { t } = useTranslation();

    const {courses,setCourses,subjectFilter, gradeFilter,setGradeSubjectFilter} = useCourseContext();
    const [isLoading, setIsLoading] = useState(true)

function setGrade(grade){setGradeSubjectFilter({subject:subjectFilter,grade})}
function setSubject(subject){setGradeSubjectFilter({subject,grade:gradeFilter})}



    useEffect(() => {
        try {
            get_cached(child(ref(rtdb), 'courses')).then((data) => {
                if (data) {
                    setCourses(Object.values(data));
                    setIsLoading(false);
                    // console.log('courses ', Object.values(data));
                } else {
                    console.log("No data available");
                }

            })
        } catch (error) {
            console.log(error);
        }
    }, []);


    const unique = courses.reduce((acc, curr) => {
        acc.subjects.add(curr.subject);
        acc.grades.add(curr.grade);
        return acc;
    }, { subjects: new Set(), grades: new Set() });



    const handleSubjectFilter = (e) => {
        setSubject(e.target.value);
    };

    const handleGradeFilter = (e) => {
        setGrade(e.target.value);
    };



    //only compute courses on dependency changes      
    // const filteredData = useMemo(
    //     () => courses.filter(item =>
    //         (!subject || item.subject === subject) &&
    //         (!grade || item.grade === grade)),
    //     [subject, grade]);




    //only compute function on dependency changes     
    const ListWrapper = useCallback(
        () => {

            let filteredData = courses.filter(item =>
                (!subjectFilter || item.subject === subjectFilter) &&
                (!gradeFilter || item.grade === gradeFilter))
            // console.log('filtered', filteredData, 'data', courses)

            return <>
                {
                    filteredData.length === 0 &&
                    <div className='mb-40'>
                        <center>No available courses for <b>{subjectFilter && t(`subject.${subjectFilter}`)}</b> {gradeFilter && <>in <b>{ t(`grade.${gradeFilter}`)}</b></>} yet</center>
                        <center>Check out our other courses:</center></div>}


                <CourseList data={filteredData.length !== 0 ? filteredData : courses} />
            </>

        },
        [subjectFilter, gradeFilter, courses],
    )
        ;



    return (
        <section className="course__area pt-120 pb-120">
            <div className="container">
                <div className="course__tab-inner grey-bg-2 mb-50">
                    <div className="row align-items-center">
                        <div className="col-12 col-md-12 col-lg-6" >
                            <div className="course__tab-wrapper d-flex align-items-center">

                                <div className="course__view">
                                    <h4>Showing {courses.length} result</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3" >
                            <div className="course__sort d-flex justify-content-center">
                                <div className='grade__select'>
                                    {t('grade.Grade')}:
                                    <div className="course__sort-inner ">

                                        <select onChange={handleGradeFilter} value={gradeFilter}>
                                            <option value="">All</option>
                                            {Array.from(unique.grades)?.map((grade) => (
                                                <option key={grade} value={grade}>
                                                    {t(`grade.${grade}`)}
                                                </option>
                                            ))}
                                        </select>
                                    </div>


                                </div>

                            </div>
                        </div>


                        <div className="col-12 col-md-6 col-lg-3" >
                            <div className="course__sort d-flex justify-content-center">
                                <div className='subject__select'>
                                    {t('subject.Subject')}:

                                    <div className="course__sort-inner ">
                                        <select onChange={handleSubjectFilter} value={subjectFilter }>
                                            <option value="">All</option>
                                            {Array.from(unique.subjects).map((subject) => (
                                                <option key={subject} value={subject} >
                                                    {t(`subject.${subject}`)}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {isLoading ?
                    <div className='d-flex justify-content-center'>
                        <Loader />
                    </div>
                    :
                    <div className="row">
                        <div className="col-xxl-12">
                            <div className="course__tab-conent">
                                <div className="row">
                                    <ListWrapper />
                                </div>

                            </div>
                        </div>
                    </div>
                }
            </div>
        </section>
    );
}
