import React, { useEffect, useState } from "react";
import MiniLoader from "../../../utils/MiniLoader";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { applyActionCode, checkActionCode, sendPasswordResetEmail } from "firebase/auth";
import { auth as auth2 } from '../../../firebase';

export default function RecoverEmail({
  auth,
  actionCode,
  continueUrl = null,
  lang = null,
}) {
  const [success, setSuccess] = useState({ success: false, message: "" });
  const [loader, setLoader] = useState(false);
 

  useEffect(() => {
    if(auth2.currentUser)  auth2.signOut()
    handleRecoverEmail(auth, actionCode);

    return () => {};
  }, []);


  function handleRecoverEmail(auth, actionCode, lang) {
    // Localize the UI to the selected language as determined by the lang
    // parameter.
    let restoredEmail = null;
    // Confirm the action code is valid.
    checkActionCode(auth, actionCode)
      .then((info) => {
        // Get the restored email address.
        restoredEmail = info["data"]["email"];

        // Revert to the old email.
        return applyActionCode(auth, actionCode);
      })
      .then(() => {
        // Account email reverted to restoredEmail

        // TODO: Display a confirmation message to the user.


        setSuccess({ success: true, message: "Email has been verified✅" });

        setLoader(false);
        toast.success("Email address has been verified.");

        // You might also want to give the user the option to reset their password
        // in case the account was compromised:
        // sendPasswordResetEmail(auth, restoredEmail)
        //   .then(() => {
        //     // Password reset confirmation sent. Ask user to check their email.
        //     setSuccess({ success: true, message: "Email has been verified✅" });

        //     setLoader(false);
        //     toast.success("Email address has been verified.");

        //             //  navigate('/')
        //   })
        //   .catch((error) => {
        //     // Error encountered while sending password reset code.
        //     setSuccess({
        //       success: false,
        //       message:
        //         "Error encountered while sending password reset code. Try again later",
        //     });

        //     setLoader(false);
        //     toast.success(
        //       "Error encountered while sending password reset code. Try again later"
        //     );
        //   });
      })
      .catch((error) => {
        // Invalid code.

        // Error encountered while sending password reset code.
        setSuccess({ success: false, message: "Invalid or expired action code. Try again later" });

        setLoader(false);
        toast.error("Invalid or expired action code. Try again later");
      });
  }

  return (
    <main>
      <section className="signup__area po-rel-z1 pt-100 pb-145">
        <div className="sign__shape">
          <img
            className="man-1"
            src="assets/img/icon/sign/man-3.png"
            alt="img not found"
          />
          <img
            className="man-2 man-22"
            src="assets/img/icon/sign/man-2.png"
            alt="img not found"
          />
          <img
            className="circle"
            src="assets/img/icon/sign/circle.png"
            alt="img not found"
          />
          <img
            className="zigzag"
            src="assets/img/icon/sign/zigzag.png"
            alt="img not found"
          />
          <img
            className="dot"
            src="assets/img/icon/sign/dot.png"
            alt="img not found"
          />
          <img
            className="bg"
            src="assets/img/icon/sign/sign-up.png"
            alt="img not found"
          />
          <img
            className="flower"
            src="assets/img/icon/sign/flower.png"
            alt="img not found"
          />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 offset-xxl-2 col-xl-8 offset-xl-2">
              <div className="section__title-wrapper text-center mb-55">
                <h2 className="section__title mt-25">Recover your Email</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-6 offset-xxl-3 col-xl-6 offset-xl-3 col-lg-8 offset-lg-2">
              <div className="sign__wrapper white-bg">
                <div className="sign__form">
                  {!loader ? (
                    <>
                      <p className={success.success ? "" : "text-danger"}>
                        {success.message}
                      </p>
                      <Link className="e-btn w-100" to="/">
                        {" "}
                        Back to Home
                      </Link>
                    </>
                  ) : (
                    <>
                      <p>Please Wait</p>
                      <MiniLoader />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
