
import { createContext, useState, useContext } from 'react';

const UserContext = createContext({ //for autoComplete
    userData: null,
    setUserData: (user) => {},

    userAuth:null,
    setUserAuth:()=>{},

    userClaims:null,
    setUserClaims:()=>{},

    verificationCodeExists:null,
    setVerificationCodeExists:()=>{},


});


export function UserContextProvider(props) { //providing context for fav and updating fav
    const [userData, setUserData] = useState(null);
    const [userAuth, setUserAuth] = useState(null);
    const [userClaims, setUserClaims] = useState(null);
    
    const [verificationCodeExists, setVerificationCodeExists] = useState(false);





    const context = {
        userData,
        setUserData,

        userAuth,
        setUserAuth,

        userClaims,
        setUserClaims,

        verificationCodeExists,
        setVerificationCodeExists,

    }


    //whatever content we wrap the conponent bellow with it will get the latest 'context' update  
    return <UserContext.Provider value={context}>
           {props.children}
           </UserContext.Provider>;
}



export const useUserContext = () => {
    const context = useContext(UserContext);
    if (context === null) {
      throw new Error('useCourseContext must be used within a CourseProvider');
    }
    return context;
  };
