import { Link } from 'react-router-dom';

import React from 'react'

import { useTranslation } from 'react-i18next';

export default function CourseItem(props) {
   const { t } = useTranslation();

   return (
      <div className="course__item white-bg mb-30 fix" onClick={props.onClick}>
         <div className="course__thumb w-img p-relative fix">
            <div>
               <img src={props.coverImg || 'assets/img/course/course-1.jpg'} alt="img not found" />
            </div>
            {/* <div className="course__tag">
                 <div className={'a '+props.color}>{props.grade}</div>
           </div> */}
         </div>
         <div className="course__content">


            <div className="course__meta d-flex align-items-center justify-content-between">
               <div className="course__lesson1">
                  <h3 className="course__title1"><div className='a'>{t(`subject.${props.subject}`)} </div></h3>
               </div>
               <div className="course__tag">
                  <div className={'a ' + props.color}> {t(`grade.${props.grade}`)}</div>
               </div>
               {/* <div className="course__rating">
           <span><i className="fas fa-book"></i><div className={"grade " }>{props.grade}</div></span>
           </div> */}
            </div>

         </div>
         <div className="course__more d-flex justify-content-between align-items-center">

            <div className="course__btn">
               <div className="a link-btn">View More<i className="fas fa-arrow-right"></i>
                  <i className="fas fa-arrow-right"></i>
               </div>
            </div>
         </div>
      </div>
   );
}
