
import React, { useCallback, useState } from 'react';
import Modal from 'react-responsive-modal';
import { gradesArray, subjectArray } from '../Home/ValuesGardeSubject';
import CourseItem from './CourseItem';

import { useNavigate, Link } from 'react-router-dom';
import { useUserContext } from '../../store/UserContext';
import { useCourseContext } from '../../store/CourseContext';
import { toast } from 'react-toastify';

function CourseList({ data, onClick }) {
  const navigate = useNavigate();

  const [modalState, setModalState] = useState(null);
  const { setGradeSubjectPair, grade, subject } = useCourseContext()
  const { userAuth,userClaims, userData } = useUserContext();


  const isUserSubscribed = (subscriptions, grade, subject) => {
    if (subscriptions) {

  
      const sub= subscriptions.find(s => s.grade === grade && s.subject === subject);
      if (sub?.expirationDate < new Date().getTime()) {
        toast.error(`Your account was expired on: ${new Date(sub?.expirationDate).toString().substring(0,15)} `);
      } else
      return sub
    }
  }

  const handleButtonClick = dataItem => {
    if (  userAuth && ( userData || userClaims?.isAdmin) ) {


     // console.log("subscriptions: ", dataItem)
      if (isUserSubscribed(userData?.subscriptions, dataItem.grade, dataItem.subject)|| userClaims?.isAdmin ) {
        console.log('subscribed');
        setGradeSubjectPair({ subject: dataItem.subject, grade: dataItem.grade })
        navigate('/course-full-view')
        return;
      } else {
        console.log('not subscribed');
        setModalState(dataItem);
      }
    } else {
      console.log('not signed in');
      navigate('/sign-in')
    }
  };

  const ListWrapper = useCallback(
    () => {
      return data.map((n, index) => (
        <div key={index} className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
          <div className="wow fadeInUp" data-wow-delay={`${index * 0.3}s`}>
            <CourseItem
              chaptersNbr={n?.chaptersNbr}
              grade={n?.grade}
              subject={n?.subject}
              color={gradesArray.find(item => item.grade === n.grade)?.bgColor}
              coverImg={subjectArray.find(item => item.subject === n.subject)?.coverImg}
              onClick={() => handleButtonClick(n)}
            />
          </div>
        </div>
      ))
    }

    ,
    [data, subject, grade]
  )

  return (
    <>
      <Modal
        center
        open={modalState !== null}
        onClose={() => setModalState(null)}
        animationDuration={600}
      >
        {userAuth && userData ? (
          !isUserSubscribed(userData?.subscriptions, modalState?.grade, modalState?.subject) ? (
            <div className='ml-40 mb-70 mt-60 mr-40'><div className="not_sign__shape">
              <img className="circle" src="assets/img/icon/sign/circle.png" alt="img not found" />
              <img className="zigzag" src="assets/img/icon/sign/zigzag.png" alt="img not found" />
              <img className="dot" src="assets/img/icon/sign/dot.png" alt="img not found" />
              <img className="flower" src="assets/img/icon/sign/flower.png" alt="img not found" />

            </div>

              <div className="section__title-wrapper text-center">
                <h2 className="section__title">Subscribe to this Course</h2>
                <b>You are not subscribed to this course.</b>
                <p>Get your subscription on Whatsapp.</p>

              </div>
              <div className="sign__form pl-50 pr-50 d-flex justify-content-center flex-column align-items-center">
                <Link style={{ color: 'white' }} className='e-btn' to='/redeem'>I have a subscription code</Link>

                <a className='e-btn-border mt-20 pl-20 pr-20' target="_blank" href={"https://wa.me/96103193993?text=Hello%20i%20%20would%20like%20to%20request%20a%20subscription%20card%20for%20a%20*" + modalState?.subject + "%20" + modalState?.grade + "*%20%20class%20"} rel="noreferrer">I want to get a new code</a>

              </div></div>
          ) : null
        ) : (
          <div><div className="not_sign__shape">
            <img className="circle" src="assets/img/icon/sign/circle.png" alt="img not found" />
            <img className="zigzag" src="assets/img/icon/sign/zigzag.png" alt="img not found" />
            <img className="dot" src="assets/img/icon/sign/dot.png" alt="img not found" />
            <img className="flower" src="assets/img/icon/sign/flower.png" alt="img not found" />

          </div>
            <div className="section__title-wrapper text-center mb-55">
              <h2 className="section__title">Sign in<br />  Account</h2>
              <p>I'm a subhead that goes with a story.</p>
            </div>
            <div className="sign__form pl-50 pr-50">

            </div></div>
        )}
      </Modal>


      <ListWrapper />

    </>
  );
}

export default CourseList;
