import React from 'react';
import Footer from '../components/Layout/Footer/Footer';
import HeaderFour from '../components/Layout/Header/HeaderStyleFour';
import NoReply from '../components/NoReply/NoReply';

export default function NoReplyPage() {


    return (
        <React.Fragment>
            <HeaderFour />
            <NoReply />
            <Footer />
        </React.Fragment>
    );

}




