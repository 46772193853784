import React from 'react';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

export default function Cta() {
    const { t } = useTranslation();


    return (
        <main>
            <section className="cta__area mb--120 wow fadeInUp" data-wow-delay="0.5s">
                <div className="container">
                    <div className="cta__inner blue-bg fix">
                        <div className="cta__shape">
                            <img src="assets/img/cta/cta-shape.png" alt="img not found" />
                        </div>
                        <div className="row align-items-center">
                            <div className="col-xxl-7 col-xl-7 col-lg-8 col-md-8">
                                <div className="cta__content">
                                    <h3 className="cta__title">{t('ctaSection.You can be your own Guiding star with our help')}</h3>
                                </div>
                            </div>
                            <div className="col-xxl-5 col-xl-5 col-lg-4 col-md-4">
                                <div className="cta__more d-md-flex justify-content-end p-relative z-index-1">
                                    <Link to="/contact" className="e-btn e-btn-white">Contact Us</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}
