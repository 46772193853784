import React from 'react';
import FooterBottom from './FooterBottom';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

const FooterThree = () => {
   const { t } = useTranslation();

   return (
      <footer>
         <div className="footer__area footer-bg">
            <div className="footer__top pt-90 pb-40">
               <div className="container">
                  <div className="row">
                     <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6">
                        <div className="footer__widget mb-50">
                           <div className="footer__widget-head mb-22">
                              <div className="footer__logo">
                                 <Link to="/"><img src="assets/img/logo/logo-2.png" alt="img not found" /></Link>
                              </div>
                           </div>
                           <div className="footer__widget-body">
                              <p>{t("footer.Education is not just about acquiring knowledge, it's about learning how to think and how to continuously grow.")}</p>

                              <div className="footer__social">
                                 <ul>
                                    <li><a href="https://www.facebook.com/edu4all.leb"><i className="fab fa-facebook-f"></i></a></li>
                                    <li><a href="https://www.instagram.com/edu4all.leb" className="tw"><i className="fab fa-instagram"></i></a></li>
                                    <li><a href="#" className="pin"><i className="fab fa-tiktok"></i></a></li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-xxl-2 offset-xxl-1 col-xl-2 offset-xl-1 col-lg-3 offset-lg-0 col-md-2 offset-md-1 col-sm-5 offset-sm-1">
                        <div className="footer__widget mb-50">
                           <div className="footer__widget-head mb-22">
                              <h3 className="footer__widget-title">{t('footer.Student')}</h3>
                           </div>
                           <div className="footer__widget-body">
                              <div className="footer__link">
                                 <ul>
                                    <li><Link to="/about">Profile</Link></li>
                                    <li><Link to="/courses">My Courses</Link></li>
                                    <li><Link to="/event-details">Get a class</Link></li>
                                    <li><Link to="/contact">Contact</Link></li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-xxl-2 col-xl-2 col-lg-2 offset-lg-0 col-md-3 offset-md-1 col-sm-6">
                        <div className="footer__widget mb-50">
                           <div className="footer__widget-head mb-22">
                              <h3 className="footer__widget-title">{t('footer.Platform')}</h3>
                           </div>
                           <div className="footer__widget-body">
                              <div className="footer__link">
                                 <ul>
                                    <li><Link to="/about">About</Link></li>
                                    <li><Link to="/courses">All Courses</Link></li>
                                    <li><Link to="/contact">Contact</Link></li>
                                    <li><Link to="/about">FAQs</Link></li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-5 col-sm-6">
                        <div className="footer__widget footer__pl-70 mb-50">
                           <div className="footer__widget-head mb-22">
                              <h3 className="footer__widget-title">Subscribe</h3>
                           </div>
                           <div className="footer__widget-body">
                              <div className="footer__subscribe">
                                 <button className='e-btn  mb-20'> Contact Us</button>
                                 <p>{t('footer.Get the latest news and updates right at your inbox.')}</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <FooterBottom />
         </div>
      </footer>
   );
}

export default FooterThree;