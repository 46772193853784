import React, { Component } from 'react';
import Hero from './HeroSection';
import Category from './CategorySection';
import Banner from './BannerSection';
import Event from './EventSection';
import Cta from './CtaSection';
import Skilline from './SkillineSection';
import WhyChoose from './WhyChooseSection';
import Counter from './CounterStyleTwo';
import PriceTab from '../Elements/Tab/PriceTab';
import CourseTab from '../Elements/Tab/CourseTab';
import GradesSection from './GradesSection'
import SliderStyleOne from '../Elements/Slider/SliderStyleOne'
import TestimonialThree from '../HomeThree/TestimonialSectionThree';

class HomeMain extends Component {

    render() {

        return (
            <main>
	            {/* hero-start 
				<Hero />
				 hero-end */}

				 	{/* slider-start */}
				<SliderStyleOne />
				{/* slider-end */}

				{/* category-start */}
				<Category />
				{/* category-end */}

				{/* banner-start 
				<Banner />
				 banner-end */}
				
				{/* banner-start */}
				<GradesSection />
				{/* banner-end */}


				 {/* testimonial-start  */}
				 <TestimonialThree />
				 {/* testimonial-end */}

				  {/* skilline-start 
				  <Skilline />
				 skilline-end */}

				  {/* counter-start 
				  <Counter />
				{/* counter-end */}

                {/* whychoose-start */}
				<WhyChoose />
				{/* whychoose-end */}		
				
				{/* cta-start */}
				<Cta />
				{/* cta-end */}

        	</main>
        );
    }
}

export default HomeMain;