import React from 'react'
import ErrorMain from '../Error/ErrorMain';
import FooterThree from '../Layout/Footer/FooterStyleThree';
import HeaderFour from '../Layout/Header/HeaderStyleFour';
import UnverifiedBrowserMain from './UnverifiedBrowserMain';

function UnverifiedBrowser() {
 
    return (
        <>
        <HeaderFour />
        <UnverifiedBrowserMain />
        <FooterThree />
        </>
    );
}

export default UnverifiedBrowser